import React, { useEffect } from 'react';
import cn from 'classnames';
import { LogoProduct } from '@dealroadshow/uikit/core/components/Logo';
import { useRouter } from 'next/router';

import { Link, TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';
import Button from '@dealroadshow/uikit/core/components/Button';
import ScrollBodyBackground from '@/Framework/UI/Templates/ScrollBodyBackground';
import CookiesBanner from '@/Framework/UI/Molecules/CookiesBanner';
import Favicon from './Favicon';
import Header from '@/Framework/UI/Pages/Landing/Sections/Header';
import SectionFooter from '@/Framework/UI/Pages/Landing/Sections/Footer';

import URL from '@/evercall/Router/urlConstants';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import { useTenantContext } from '@/Framework/Tenant/TenantContext';
import {
  EVERCALL_LANDING_HEADER_MENU,
  EVERCALL_LANDING_FOOTER_CONFIG,
} from './constants';

import stickyFooterStyles from '@/Framework/UI/Organisms/StickyFooter/stickyFooter.scss';
import styles from './layout.scss';

interface IProps {
  children: React.ReactNode,
  containerCls?: string,
}

const LandingLayout = ({
  children,
  containerCls,
}: IProps) => {
  const { tenant } = useTenantContext();
  const { asPath } = useRouter();

  useEffect(() => {
    const id = asPath.split('#')[1];

    if (id) {
      document.getElementById(id)
        ?.scrollIntoView();
    }
  }, []);

  const pageContainerCls = cn(
    styles.landingPageContainer,
    stickyFooterStyles.pageContainerWithStickyFooter,
    containerCls,
  );

  return (
    <ContentWrp>
      <Favicon />
      <Header
        logoProduct={ LogoProduct.EVERCALL }
        menu={ EVERCALL_LANDING_HEADER_MENU }
        customLoginButton={ ({
          buttonProps,
          linkProps,
        }) => (
          <NextNavLink
            { ...linkProps }
            to={ evercallUrl.getLoginUrl() }
          >
            <Button { ...buttonProps } />
          </NextNavLink>
        ) }
        headerButton={ ({ buttonProps }) => (
          <Link to={ URL.SCHEDULE }>
            <Button
              { ...buttonProps }
              title="Schedule Your Event"
              dataTest="scheduleEventButton"
            />
          </Link>
        ) }
        mobileMenuClassName={ styles.mobileMenu }
      />
      <div className={ pageContainerCls }>
        <ScrollBodyBackground tenant={ tenant } />
        { children }
      </div>
      <SectionFooter
        config={ EVERCALL_LANDING_FOOTER_CONFIG }
        contentClassName={ styles.footerContent }
      />
      <CookiesBanner />
    </ContentWrp>
  );
};

export default LandingLayout;
