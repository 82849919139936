import SectionWithImages from '@/Framework/UI/Pages/Landing/Sections/Tabs/SectionWithImages';
import { ITabList } from '@/Framework/UI/Pages/Landing/Sections/Tabs/interfaces';
import { Link } from '@/Framework/Router/Next/Link';
import DialInsPopover from './DialInsPopover';
import URL from '@/evercall/Router/urlConstants';
import { CALCULATOR_SECTION_ID } from '@/evercall/ui/components/Pricing/constant';
import eventSolutionsImg from './assets/eventSolutions.webp';
import schedulingImg from './assets/scheduling.webp';
import executionImg from './assets/execution.webp';
import participationImg from './assets/participation.webp';
import customizationImg from './assets/customization.webp';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';

import styles from './features.scss';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';
import IconStar from '@dealroadshow/uikit/core/components/Icon/IconStar';
import IconCalendar from '@dealroadshow/uikit/core/components/Icon/IconCalendar';
import IconDealroadshow from '@dealroadshow/uikit/core/components/Icon/IconDealroadshow';
import IconUsersGroup from '@dealroadshow/uikit/core/components/Icon/IconUsersGroup';
import IconFilters from '@dealroadshow/uikit/core/components/Icon/IconFilters';

const Feature = ({
  head,
  text,
}: { head: string, text: React.ReactNode }) => (
  <div className={ styles.feature }>
    <div className={ styles.featureIcon }>
      <IconCheck />
    </div>
    <div className={ styles.textBlock }>
      <div className={ styles.featureHead }>{ head }</div>
      <div className={ styles.featureText }>{ text }</div>
    </div>
  </div>
);

export const featuresTabsList: ITabList[] = [
  {
    key: 'eventSolutions',
    icon: IconStar,
    label: 'Event Solutions',
    content: (
      <SectionWithImages
        images={ [
          {
            src: eventSolutionsImg,
            width: 1420,
            height: 896,
            key: 1,
          },
        ] }
        caption="Ensure your event runs smoothly for all stakeholders with Evercall operator assisted conference call & webinar services."
      >
        <Feature
          head="Operator Assisted Conference Calls"
          text="Public & private operator assisted conferencing on a high-end, easy-to-use platform."
        />
        <Feature
          head="Operator Assisted Webinars"
          text="Elevated moderated events on Zoom, Teams or Webex with live speaker video and visual assets."
        />
        <Feature
          head="Operator Managed Meetings"
          text="Private moderated 1-on-1 or small group meetings with live speaker video and visual assets."
        />
        <Feature
          head="Pre-Recorded Audio & Video Services"
          text="Professionally edited recordings synced to visual assets for your event."
        />
      </SectionWithImages>
    ),
  },
  {
    key: 'scheduling',
    icon: IconCalendar,
    label: 'Scheduling',
    content: (
      <SectionWithImages
        images={ [
          {
            src: schedulingImg,
            width: 1420,
            height: 896,
            key: 2,
          },
        ] }
        caption={ (
          <>
            Instantly schedule an Evercall via <Link to={ URL.SCHEDULE }>our website</Link> or log in to the
            Evercall Deal Manager Portal.
          </>
        ) }
      >
        <Feature
          head="24x7 Instant Scheduling"
          text={ (
            <>
              Choose your <Link to={ URL.PRICING }>event type</Link>, fill in call details and receive confirmation from
              our team within 2 hours.
            </>
          ) }
        />
        <Feature
          head="Guaranteed Availability"
          text="Evercall needs just 6 hours of notice ahead of your event to guarantee white glove execution."
        />
        <Feature
          head="International Dial-ins & Languages"
          text={ (
            <>
              Support for dozens of <DialInsPopover>international dial-ins</DialInsPopover>, Join via Web functionality,
              and moderators who speak your language.
            </>
          ) }
        />
        <Feature
          head="Expertly Trained Moderators"
          text="Evercall moderators are carefully selected, professional, articulate and calm under pressure."
        />
      </SectionWithImages>
    ),
  },
  {
    key: 'execution',
    icon: IconDealroadshow,
    label: 'Execution',
    content: (
      <SectionWithImages
        images={ [
          {
            src: executionImg,
            width: 1420,
            height: 896,
            key: 3,
          },
        ] }
        caption="Distribute a single entry code with access to dial-in details and your presentation on Deal Roadshow."
      >
        <Feature
          head="Seamless Participant Entry"
          text="Allow participants to pre-register and choose between automated and live operator check-ins."
        />
        <Feature
          head="Live Participant Dashboards"
          text="See live participants lists, manage questions and chat with your moderator and speakers throughout the call."
        />
        <Feature
          head="Speaker Pre-Conference Room"
          text="Coordinate plans with your operator in a “Green Room” leading up to your event."
        />
        <Feature
          head="Deal Roadshow Integration"
          text={ (
            <>
              Distribute one Entry Code for participants to access dial-in details and lead your presentation hosted
              on{ ' ' }
              <a href={ drsUrl.getUrl() } target="_blank" data-test="featuresDrsLink">
                Deal Roadshow
              </a>
              .
            </>
          ) }
        />
      </SectionWithImages>
    ),
  },
  {
    key: 'participation',
    icon: IconUsersGroup,
    label: 'Participation',
    content: (
      <SectionWithImages
        images={ [
          {
            src: participationImg,
            width: 1420,
            height: 896,
            key: 4,
          },
        ] }
        caption="Call details, including participant lists and engagement analytics are stored on the Deal Manager Portal in perpetuity."
      >
        <Feature
          head="Professional Post-Event Replays"
          text={ (
            <>
              Host an audio or video event replay{ ' ' }
              <Link to={ `${ URL.PRICING }#${ CALCULATOR_SECTION_ID }` }>
                telephonically or on Deal Roadshow
              </Link>
              .
            </>
          ) }
        />
        <Feature
          head="Client-Ready Participant Lists"
          text="Clean participant lists promptly sent to your inbox and available on the Deal Manager Portal."
        />
        <Feature
          head="Historical Analytics Archive"
          text="Institutionalize historical call details and participant engagement in perpetuity, for free."
        />
        <Feature
          head="Rapid Transcription & Translations"
          text="Receive accurate, client-ready event transcriptions and translations in your preferred language within hours."
        />
      </SectionWithImages>
    ),
  },
  {
    key: 'customization',
    icon: IconFilters,
    label: 'Customization',
    content: (
      <SectionWithImages
        images={ [
          {
            src: customizationImg,
            width: 1420,
            height: 896,
            key: 1,
          },
        ] }
        caption="Customize an Evercall registration page for participants to access event details."
      >
        <Feature
          head="Script Customization"
          text="Personalize your event with a custom introduction or script for our moderators."
        />
        <Feature
          head="Pre-Recorded Audio & Video Playback"
          text="Pre-record your message to deliver a simulated live experience for participants."
        />
        <Feature
          head="Registration Page Branding"
          text="Showcase your brand on a custom registration page linked to automated RSVP reports."
        />
        <Feature
          head="Multi-Platform Compatibility"
          text="Use Zoom, Teams, or Webex for your Webinar or Small Group Meetings. We’re happy to provide recommendations."
        />
      </SectionWithImages>
    ),
  },
];
