import React from 'react';
import CountryFlag from '@dealroadshow/uikit/core/components/CountryFlag';
import styles from './dialInItem.scss';

interface IProps {
  countryCode: string,
  countryName: string,
  phoneCode: string,
}

const DialInItem = (
  {
    countryCode,
    countryName,
    phoneCode,
  }: IProps,
) => (
  <div className={ styles.row }>
    <div className={ styles.dialInCountry }>
      <CountryFlag
        className={ styles.flagIcon }
        countryCode={ countryCode.toLowerCase() }
      />
      <span className={ styles.dialInCountryName }>
        { countryName }
      </span>
    </div>
    <span className={ styles.dialInPhone }>
      { phoneCode }
    </span>
  </div>
);

export default DialInItem;
