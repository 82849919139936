import { Link } from '@/Framework/Router/Next/Link';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import CallToAction from '@/Framework/UI/Pages/Landing/Sections/CallToAction';
import URL from '@/evercall/Router/urlConstants';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import landingStyles from '@/Framework/UI/Pages/Landing/Sections/base.scss';

const GetStarted = () => {
  return (
    <CallToAction
      dataTest="getStartedSection"
      title="Ready to get started?"
      description="Evercall is ready when you are. Schedule your operator assisted conference call now or contact us."
      actions={ (
        <div className={ alignStyles.alignCenter }>
          <Link to={ URL.SCHEDULE }>
            <Button
              variant={ ButtonVariantType.action }
              className={ landingStyles.actionButton }
              title="Schedule Your Event"
              dataTest="scheduleEventButton"
            />
          </Link>
        </div>
      ) }
    />
  );
};

export default GetStarted;
